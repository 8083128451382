import { useApolloClient, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Movie } from '../gql/graphql-types';
import { useNavigate } from 'react-router-dom';
import { GET_MOVIES_WITH_DETAILS } from '../gql/queries/MovieQueries';
import AddMovie from '../components/AddMovie';
import Page from '../components/Page';
import MovieTable from '../components/MovieTable';
import styled from '@emotion/styled';
import { Button } from 'antd';
import { EmphasisContainer } from './snips/NextShowingSnip';

function Movies() {
  const { data, loading, error } = useQuery(GET_MOVIES_WITH_DETAILS);
  // const [showing, setShowing] = useState<Maybe<Showing>>(null);
  const nav = useNavigate()
  const movies = data?.movies || [];

  return (
    <Page title={<h1>Movies</h1>}>
      {loading ? 'Loading...' : (
        <>
        <section>
          <EmphasisContainer>
            <AddAMovie />
          </EmphasisContainer>
          <MovieTable movies={movies} />
        </section>
        </>
      )}
    </Page>
  );
}


function AddAMovie () {
  const client = useApolloClient()
  const [isEditMode, setIsEditMode] = useState(false)

  return (
    <div>
      {
        isEditMode ? (
          <div>
            <AddMovie onAdd={(m) => {
                client.refetchQueries({
                  include: [GET_MOVIES_WITH_DETAILS]
                })
            }} />
          </div>
        ) : (
          <Button onClick={() => setIsEditMode(true)}>
            Add Movie
          </Button>
        )
      }
    
    </div>
  )
}

export default Movies;
