import { gql } from "@apollo/client";
import { SCORE_FRAGMENT } from "./ScoreQueries";

export const GET_SHOWINGS = gql`
query Showings {
  showings {
      id
      date
      createdAt
      movie {
        id
        title
      }
      series {
        id
        name
      }
      scores {
        id
        value
      }
  } 
}
`


export const GET_SHOWING_CATEGORIES = gql`
query GetShowingCategories($where: ShowingWhereUniqueInput!) {
  showing(where: $where) {
      id
      series {
        id
        name
        categories {
          id
          name
        }
      }
  } 
}
`




export const GET_NEXT_SHOWING = gql`
query GetNextShowing($date: DateTime) {
  nextShowing: findFirstShowing(orderBy: { date: asc }, where: { date: { gte: $date } }) {
    id
    date
    movie {
      id
      title
    }
    series {
      id
      name
      categories {
        id
        name
        type
      }
    }
  }
}
`

export const GET_SHOWING = gql`
query Showing($where: ShowingWhereUniqueInput!) {
  showing(where: $where) {
      id
      date
      createdAt
      movie {
        id
        title
      }
      series {
        id
        name
      }
  } 
}
`

export const GET_SCORES_FOR_SHOWING = gql`
query GetScoresForShowing($where: ShowingWhereUniqueInput!) {
  showing(where: $where) {
    id
    scores {
      ...ScoreFragment
    }
  }
}

${SCORE_FRAGMENT}
`


export const GET_SHOWING_WITH_SCORES = gql`
query GetScoresWithShowing($where: ShowingWhereUniqueInput!) {
  showing(where: $where) {
      id
      date
      createdAt
      movie {
        id
        title
      }
      series {
        id
        name
      }
      scores {
        ...ScoreFragment
      }
  } 
}
${SCORE_FRAGMENT}
`




export const GET_SCORES_FOR_MOVIE = gql`
query GetScoresForMovie($where: MovieWhereUniqueInput!) {
  movie(where: $where) {
    id
    scores {
      ...ScoreFragment
    }
  }
}

${SCORE_FRAGMENT}
`

export const GET_MOVIE_DETAILS = gql`
query GetMovieDetails($where: MovieWhereUniqueInput!) {
  movie(where: $where) {
    id
    scientist {
      id
      name
    }
    scores {
      ...ScoreFragment
    }
  }
}

${SCORE_FRAGMENT}
`


export const GET_SHOWING_LIKE = gql`
query GetShowingLike($where: ShowingWhereInput) {
  showings(where: $where) {
      id
      movie {
        id
        title
      }
      series {
        id
        name
      }
  }
}
`