import styled from '@emotion/styled';
import React from 'react';
import ShowingBallot from '../components/ShowingBallot/ShowingBallot';
import { Showing, ShowingWhereUniqueInput } from '../gql/graphql-types';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_SHOWING } from '../gql/queries/ShowingQueries';
import Page from '../components/Page';
import dayjs from 'dayjs';

const HomeWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  padding: 5vw;
`

const IntroWrapper = styled.div`
  display: grid;
  width: 90vw;
`

const LinksWrapper = styled.div`
margin-top: 10px;
  display: grid;
  grid: 1fr / min-content;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  gap: 10px;
`

function BallotPage() {
  const { id } = useParams()
  const { data, loading, error } = useQuery(GET_SHOWING, { variables: { where: { id: Number(id) } } as ShowingWhereUniqueInput })

  const showing: Showing = data?.showing;

  return (
    <Page title={ loading ? <h1>'Loading Showing..'</h1> : <h1>{dayjs(showing?.date).format("MMM D YYYY")}: {showing?.movie?.title}</h1>}>
      {
        loading ? (
          <span> Loading... </span >
        ) : (
          <ShowingBallot showing={showing} />
        )
      }
    </Page>
  )
}

export default BallotPage;
