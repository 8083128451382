import React, { useState } from 'react';
import { Series } from '../../gql/graphql-types';
import { Maybe } from 'graphql/jsutils/Maybe';
import { GET_SERIES_LIKE } from '../../gql/queries/SeriesQueries';
import Selector from './Selector';

interface SeriesSelectorProps {
    onSelect: (movie: Maybe<Series>) => unknown
}

function SeriesSelector({ onSelect }: SeriesSelectorProps) {
    const [isEdit, setIsEdit] = useState(false);

    return (
        <Selector
            name="Series"
            query={GET_SERIES_LIKE}
            accessor={(res) => {
                return res?.series || []
            }}
            labelSelector={(s: Series) => s.name}
            onSelect={onSelect}
            where={value => {
                return { name: { contains: value } }
            }}
        />
    )
}


export default SeriesSelector