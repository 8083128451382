import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import Showing from '../components/Showing/Showing';
import { Showing as ShowingType, ShowingCreateInput, ShowingWhereUniqueInput, Score, Series, Movie, Maybe } from '../gql/graphql-types';
import { Link, useNavigate, useNavigation, useParams } from 'react-router-dom';
import MovieSelector from '../components/selectors/MovieSelector';
import SeriesSelector from '../components/selectors/SeriesSelector';
import { Dayjs } from 'dayjs';
import { Button, DatePicker } from 'antd';
import { ADD_SHOWING } from '../gql/mutations/ShowingMutations';

function NewShowing() {
  const nav = useNavigate();
  const [addShowing, { data, loading, error }] = useMutation(ADD_SHOWING);

  const [movie, setMovie] = useState<Maybe<Movie>>(null);
  const [series, setSeries] = useState<Maybe<Series>>(null);
  const [date, setDate] = useState<Maybe<Dayjs>>(null);

  const canSubmit = movie && series && date
  const onSubmit = async () => {
    const showing = await addShowing(
      {
        variables: {
          data: {
            date,
            movie: { connect: { id: movie?.id } },
            series: { connect: { id: series?.id } },
          } as ShowingCreateInput
        }
      }
    )

    const id = showing?.data?.createOneShowing?.id;
    if (id) {
      nav(`/showings/${id}`)
    }
  };

  return (
    <div>
      <h1>New Showing</h1>
      <table>
        <tbody>
          <tr>
            <td><strong>Movie</strong></td>
            <td><MovieSelector onSelect={(m) => setMovie(m || null)} /></td>
          </tr>
          <tr>
            <td><strong>Series</strong></td>
            <td><SeriesSelector onSelect={(s) => setSeries(s || null)} /> </td>
          </tr>
          <tr>
            <td><strong>Date</strong></td>
            <td><DatePicker onChange={(date) => setDate(date)} /></td>
          </tr>
          <tr>
            <td colSpan={2} style={{ textAlign: "center" }}>
              <Button disabled={!canSubmit} onClick={onSubmit}>{loading ? 'Submitting...' : 'Add Showing'}</Button>
            </td>
          </tr>
        </tbody>
      </table>

      {error && JSON.stringify(error)}

    </div>
  );
}


export default NewShowing;
