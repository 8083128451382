import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useState } from 'react';
import { Category, Movie, Scientist, Score as ScoreType, Series, Showing } from '../../gql/graphql-types';
import { GET_SCORES_FOR_SHOWING, GET_SHOWING_CATEGORIES } from '../../gql/queries/ShowingQueries';
import Score from '../Score';
import ScientistSelector from '../selectors/ScientistSelector';
import ShowingComponent from '../Showing/Showing'
import MovieLink from '../MovieLink';
import SeriesLink from '../SeriesLink';
import { prop, sortBy } from 'ramda';
import { Switch } from 'antd';

interface ShowingBallotProps {
    showing: Maybe<Showing>
}

const ShowingBallotWrapper = styled.div`
    
`

const Emphasis = styled.div`
    padding: 1em;
    border: 1px solid black;
`

const BallotHeader = styled.div`
    display: grid;
    gap: 1em;
    grid: 1fr / auto min-content;
    align-items: center;
    grid-auto-columns: auto;
    white-space: nowrap;
`

const ScoreWrapper = styled.div`
`

function ShowingBallot({ showing }: ShowingBallotProps) {
    const [showDescription, setShowDescription] = useState(false);
    const [scientist, setScientist] = useState<Maybe<Scientist>>(null);
    const { loading, data, error } = useQuery(GET_SHOWING_CATEGORIES, { variables: { where: { id: showing?.id } } })

    const series: Maybe<Series> = data?.showing?.series;
    const categories: Category[] = data?.showing?.series?.categories || []
    const movie = showing?.movie

    if (!showing) return null

    return (
        <ShowingBallotWrapper>
            <ShowingComponent id={showing?.id} />
            <hr/>
            { !scientist && <ScientistSelector onSelect={setScientist} /> }
            {scientist && (
                <>
                <BallotHeader>
                    <h3>Oh, hey {scientist.name}! What did you think of <MovieLink movie={movie}  />?</h3>
                    <label>
                        <strong>Show Descriptions</strong>
                        <Switch onClick={() => setShowDescription(!showDescription)} checked={showDescription}/>
                    </label>
                </BallotHeader>
                        <div>
                            {
                                categories.length ? (
                                    <ScoreWrapper>
                                        {
                                            sortBy(prop("name"), categories).map(c => {
                                                return (
                                                    <Score
                                                        key={c.id}
                                                        categoryId={c.id}
                                                        scientistId={scientist?.id}
                                                        showingId={showing.id}
                                                        movieId={showing.movie.id}
                                                        showDescription={showDescription}
                                                        onScoreUpdate={(score) => console.log(score)}
                                                    />
                                                )
                                            })
                                        }
                                    </ScoreWrapper>
                                ): (
                                    <Emphasis>
                                        No Categories Found. You can add some on the page for <SeriesLink series={series} />, though!
                                    </Emphasis>
                                )
                            }
                        </div>
                </>
            )}
        </ShowingBallotWrapper>
    )
}


export default ShowingBallot