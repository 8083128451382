import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import React from "react";
import { Score, MovieWhereUniqueInput, Showing } from "../gql/graphql-types";
import { GET_SCORES_FOR_MOVIE } from "../gql/queries/ShowingQueries";
import { useParams } from "react-router-dom";
import { mean } from "ramda";
import { GET_MOVIE, GET_MOVIE_WITH_DETAILS } from "../gql/queries/MovieQueries";
import Page from "../components/Page";
import ScoreTable from "../components/ScoreTable";
import { EmphasisContainer } from "./snips/NextShowingSnip";
import BallotLink from "../components/BallotLink";

const ScoreGrid = styled.section`
  display: grid;
  grid: 1fr / 1fr 1fr 1fr;
  gap: 10vw;
  /* padding: 0 2vw; */
`;

const ScoreValue = styled.span`
  text-align: right;
`;

const ScoreTotal = styled.span`
  font-weight: 500;
  grid-area: auto / 3;
  text-align: right;
  font-size: 2em;
`;

function MoviePage() {
  const { id } = useParams();
  const { data, loading, error } = useQuery(GET_MOVIE_WITH_DETAILS, {
    variables: { where: { id: Number(id) } } as MovieWhereUniqueInput,
  });
  const movie = data?.movie;
  const { data: scoresData } = useQuery(GET_SCORES_FOR_MOVIE, {
    variables: { where: { id: Number(id) } } as MovieWhereUniqueInput,
  });

  const scores = scoresData?.movie?.scores || [];

  return (
    <Page title={<h1>{movie?.title || "Loading..."}</h1>}>
      {loading ? (
        "Loading..."
      ) : (
        <>
          {<EmphasisContainer>
            <ul>
            {
              movie?.showings?.map((s: Showing) => {
                return <li><BallotLink showing={s} /></li>
              })
            }
            </ul>
            </EmphasisContainer>}
          {scores.length > 0 ? (
            <>
              <h3>Scores</h3>
              <ScoreTable scores={scores} />
            </>
          ) : (
            <h4>No Scores Found</h4>
          )}
        </>
      )}
    </Page>
  );
}

export default MoviePage;
