import React from 'react';
import { Movie, Showing } from '../gql/graphql-types';
import * as colors from '@ant-design/colors';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

const StyledLink = styled(Link)`
    color: ${colors.blue[8]};
`

function BallotLink({showing, isSmall = false}: {showing?: Showing, isSmall?: boolean}) {
    if (!showing) {
        return <StyledLink to={window.location}>---</StyledLink>
    }

    if (isSmall) {
        return (
            <StyledLink to={`/ballots/${showing.id}`}>
                <em>{showing.scores.length} Votes</em>
            </StyledLink>
        )
    } else {
        return (
            <StyledLink to={`/ballots/${showing.id}`}>
                <em>{showing.movie.title} on {dayjs(showing.date).format("MMM HH YYYY")}</em>
            </StyledLink>
        )
    }

}

export default BallotLink