import { gql } from "@apollo/client"

export const GET_ALL_SERIES = gql`
  query GetSeries {
    series: findManySeries {
      id
      isCurrent
      name
      createdAt
    }
  }
`


export const GET_SERIES_LIKE = gql`
  query GetSeriesLike($where: SeriesWhereInput) {
    series: findManySeries(where: $where) {
      id
      isCurrent
      name
      createdAt
    }
  }
`

export const GET_CURRENT_SERIES = gql`
  query GetCurrentSeries {
    series: findFirstSeries(where: { isCurrent: {equals: true} }) {
      id
      isCurrent
      name
      createdAt
      movies {
        id
        description
        title
        createdAt
        scientist {
          id
          name
        }
        showings {
          id
          date
        }
        scores {
          id
          value
        }
      }
      categories {
        id
        name
        description
      }
      showings {
        id
        date
        movie {
          id
          title
        }
        scores {
          id
          value
          scientist {
            id
            name
          }
          category {
            id
            name
          }
          movie {
            id
            title
          }
        }
      }
    }
  }
`




export const GET_SERIES_BY_ID = gql`
# {
#   "where": {
#     "id": {
#       "in": 3
#     }
#   }
# }
query GetSeriesById($where: SeriesWhereInput) {
  series: findFirstSeries(where: $where) {
    id
    name
    isCurrent
    createdAt
    categories {
      id
      name
      description
    }
    movies {
      id
      title
      description
      scientist {
        id
        name
      }

      scores {
        id
        value
      }

      showings {
        id
        date
      }
    }
    showings {
      id
      date
      movie {
        id
        title
      }
      scores {
        id
        value
        scientist {
          id
          name
        }
        category {
          id
          name
        }
        movie {
          id
          title
        }
      }
    }
  }
}
`
