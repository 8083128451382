import { gql } from "@apollo/client";
import { SCORE_FRAGMENT } from "../queries/ScoreQueries";

export const ADD_SCORE = gql`
  mutation AddScore($data: ScoreCreateInput!) {
    score: createOneScore(data: $data) {
      ...ScoreFragment
    }
  }
  ${SCORE_FRAGMENT}
`

export const UPDATE_SCORE = gql`
mutation UpdateScore($data: ScoreUpdateInput!, $where: ScoreWhereUniqueInput!) {
  score: updateOneScore(data: $data, where: $where) {
    ...ScoreFragment
  }
}
${SCORE_FRAGMENT}
`