import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { Button, Input } from 'antd';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useEffect, useState } from 'react';
import { Category, Movie, MovieCreateInput, Scientist, Score, ScoreCreateInput, Series, Showing, ShowingCreateInput } from '../gql/graphql-types';
import { ADD_MOVIE } from '../gql/mutations/MovieMutations';
import { ADD_SCORE } from '../gql/mutations/ScoreMutations';
import { ScoreProps } from './Score';
import CategorySelector from './selectors/CategorySelector';
import ScientistSelector from './selectors/ScientistSelector';
import ShowingSelector from './selectors/ShowingSelector';
import SeriesSelector from './selectors/SeriesSelector';

interface AddMovieProps {
    onAdd: (m: Movie) => unknown
}

const AddMovieWrapper = styled.div`
    display: grid;
    /* grid: 1fr / 2fr 2fr 2fr 1fr; */
    grid-auto-flow: column;
    grid-auto-rows: 1fr;
    grid-auto-columns: auto;
    gap: 1em;
    align-items: center;
`

function AddMovie ({onAdd}: AddMovieProps) {
    const [title, setTitle] = useState<string>('');
    const [scientist, setScientist] = useState<Maybe<Scientist>>(null);
    const [series, setSeries] = useState<Maybe<Series>>(null);

    const [addMovie, {loading, data, error}] = useMutation(ADD_MOVIE)

    const readyToSubmit = !!scientist && !!series && !!title

    useEffect(() => {
        const hasData = data?.movie
        if (!loading && hasData) {
            onAdd(data?.movie)

            setTitle('')
        }
    }, [loading, data])

    return (
        <AddMovieWrapper>
            <label>
                <strong>Title</strong>
                <Input value={title} onChange={(e) => setTitle(e?.currentTarget?.value)}></Input>
            </label>
            <label>
                <strong>Scientist</strong>
                <div>
                    <ScientistSelector onSelect={s => setScientist(s)} />
                </div>
            </label>
            <label>
                <strong>Series</strong>
                <div>
                    <SeriesSelector onSelect={s => setSeries(s)} />
                </div>
            </label>
            <Button disabled={!readyToSubmit} onClick={() => {
                if (readyToSubmit) {
                    addMovie({
                        variables: {
                            data: { 
                                title,
                                scientist: { connect: { id: scientist.id } },
                                series: { connect: { id: series.id } }
                             }
                        } 
                    })
                }
            }}>Add Movie</Button>
        </AddMovieWrapper>
    )
}


export default AddMovie