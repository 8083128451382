import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import Showing from '../components/Showing/Showing';
import { Category, QueryFindFirstShowingArgs, Showing as ShowingType, ShowingCreateInput, ShowingWhereUniqueInput, Score, Series } from '../gql/graphql-types';
import { GET_SCORES_FOR_SHOWING, GET_SHOWING, GET_SHOWINGS } from '../gql/queries/ShowingQueries';
import { Link, useNavigation, useParams } from 'react-router-dom';
import { GET_ALL_SERIES } from '../gql/queries/SeriesQueries';

function SeriesNew() {
  const { data, loading, error } = useQuery(GET_ALL_SERIES);
  const series: Series[] = data?.series;


  return (
    <div>
      {loading ? 'Loading...' : (
        <>
          {
            series.map((s) => {
              return (
                <div key={s.id}>
                  <Link to={`/series/${s.id}`}>
                    <h1>{s.name}</h1>
                  </Link>
                </div>
              )
            })
          }
        </>
      )}
    </div>
  );
}


export default SeriesNew;
