import { gql } from "@apollo/client"


export const GET_CATEGORY = gql`
query GetCategory($where: CategoryWhereUniqueInput!) {
  category(where: $where) {
    id
    name
    description
    createdAt
  }
}
`


export const GET_CATEGORIES = gql`
query GetCategories {
  categories {
    id
    name
    description
    createdAt
  }
}
`

export const GET_CATEGORIES_LIKE = gql`
query GetCategoriesLike($where: CategoryWhereInput) {
  categories(where: $where) {
    id
    name
    description
    createdAt
  }
}
`
