import { groupBy, prop, propOr } from "ramda";
import { Score } from "../gql/graphql-types";

export const groupByCategory = (scores: Score[]) => {
    return groupBy((s) => selectCategoryId(s).toString(), scores)
}


export const selectValue = prop("value")

export const selectCategoryId = (score: Score) => {
    return prop("id", prop("category", score))
}

