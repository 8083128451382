import { gql } from "@apollo/client";

export const ADD_SHOWING = gql`
mutation AddShowing($data: ShowingCreateInput!) {
  createOneShowing(data: $data) {
    id
    movie {
      id
      createdAt
      title
    }
    series {
        id
        name
        categories {
          id
          name
        }
      }
  }
}
`