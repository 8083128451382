import { gql } from "@apollo/client";
import { SCORE_FRAGMENT } from "../queries/ScoreQueries";

export const ADD_SERIES = gql`
mutation AddSeries($name: String!) {
  createOneSeries(data: { name: $name, isCurrent: false }) {
    id
    name
    createdAt
  }
}
`

export const ADD_CATEGORY_TO_SERIES = gql`
mutation AddCategoryToSeries($categoryName: String!, $seriesId: Int!) {
  updateOneSeries(data: {categories: {connectOrCreate: {create: {name: $categoryName, type: VALUE}, where: {name: $categoryName}}}}, where: {id: $seriesId}) {
    id
    name
    categories {
      id
      name
    }
  }
}
`


export const UNSET_CURRENT_SERIES = gql`
mutation UnsetCurrentSeries{
    updateManySeries(data: { isCurrent: {set: false} }, where: { isCurrent: { equals: true } }) {
        count
    }
}
`


export const SET_CURRENT_SERIES = gql`
mutation SetCurrentSeries($seriesId: Int!){
    updateOneSeries(data: {isCurrent: {set: true}}, where: { id: $seriesId }) {
        id
        name
        isCurrent
    }
}
`

export const UPDATE_SCORE = gql`
mutation UpdateScore($data: ScoreUpdateInput!, $where: ScoreWhereUniqueInput!) {
  score: updateOneScore(data: $data, where: $where) {
    ...ScoreFragment
  }
}
${SCORE_FRAGMENT}
`