import { gql } from "@apollo/client";

export const ADD_MOVIE = gql`
mutation AddMovie($data: MovieCreateInput!) {
  movie: createOneMovie(data: $data) {
    id
    createdAt
    title
  }
}
`

export const ADD_MOVIE_TO_SERIES = gql`
mutation AddMovieToSeries($movieId: Int!, $seriesId: Int!) {
  movie: updateOneMovie(data: { series: {connect: {id: $seriesId }} }, where: { id: $movieId }) {
    id
    createdAt
    title
  }
}
`