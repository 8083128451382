import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Slider } from 'antd'
import { Maybe } from 'graphql/jsutils/Maybe'
import React, { useCallback, useEffect, useState } from 'react'
import { Score as ScoreType, ScoreCreateInput, ScoreUpdateInput } from '../gql/graphql-types'
import { ADD_SCORE, UPDATE_SCORE } from '../gql/mutations/ScoreMutations'
import { GET_CATEGORY } from '../gql/queries/CategoryQueries'
import { GET_SCORE } from '../gql/queries/ScoreQueries'
import { blue, green, red, yellow } from '@ant-design/colors'
import { debounce } from '../utils/debounce'

export const valueToColor = (value: number) => {
    if (value === 0) return '#FFF'
    if (value < 2) return red[6]
    if (value < 3.5) return yellow[6]
    if (value < 4) return blue[6]

    return green[6]
}

export interface ScoreProps {
    categoryId: number,
    scientistId: number,
    movieId: number,
    showingId: number,
    showDescription?: boolean,
    onScoreUpdate: (score: Maybe<ScoreType>) => unknown
}

const ScoreWrapper = styled.div`
    display: grid;
    grid: 1fr / 1fr 3fr;
    gap: 5px;
    align-items: center;
`

const Value = styled.div`
    display: grid;
    background: ${({value = 0}: {value: number}) => valueToColor(value)};
    color: white;
    text-align: center;
    font-size: 3em;
    font-weight: 600;
    padding: .25em;

    transition: background ease-in-out 100ms;
`

const ScoreCategory = styled.div`
    font-size: 1.125em;
    font-weight: 500;
`

function Score({ categoryId, scientistId, showingId, movieId, showDescription = false, onScoreUpdate }: ScoreProps) {
    const client = useApolloClient();
    const { loading, data, error } = useQuery(GET_SCORE, { variables: where(categoryId, scientistId, showingId, movieId) })
    const { data: category } = useQuery(GET_CATEGORY, { variables: { where: { id: categoryId } } })
    const score: ScoreType = data?.score

    const [value, setValue] = useState(0);

    useEffect(() => {
        if (!loading) {
            setValue(data?.score?.value)
        }
    }, [loading])

    const [updateScore] = useMutation(UPDATE_SCORE)
    const [addScore] = useMutation(ADD_SCORE)

    const debouncedUpdateScore = useCallback(debounce(200, updateScore)(), [])
    const debouncedAddScore = useCallback(debounce(200, addScore)(), [])

    if (error) {
        console.error(error)
    }


    return (
        <ScoreWrapper>
            <div>
                <Value value={value}>{value || 0}</Value>
            </div>
            <div>
                <ScoreCategory>{category?.category.name}</ScoreCategory>
                {showDescription && <span>{category?.category.description}</span>}
                <Slider min={1} max={5} step={.25} value={value} tooltip={{ open: false }} disabled={loading} onChange={(value) => {
                    setValue(value)
                    if (data.score) {
                        debouncedUpdateScore({ variables: { where: { id: score?.id }, data: { value } } as ScoreUpdateInput })
                    } else {
                        debouncedAddScore({
                            variables: {
                                data: {
                                    value,
                                    scientist: { connect: { id: scientistId } },
                                    category: { connect: { id: categoryId } },
                                    showing: { connect: { id: showingId } },
                                    movie: { connect: { id: movieId } }
                                } as ScoreCreateInput
                            }
                        })

                        client.refetchQueries({ include: [GET_SCORE] })
                    }
                }}
                />
            </div>
        </ScoreWrapper>
    )

}


const where = (categoryId: number, scientistId: number, showingId: number, movieId: number) => (
    {
        "where": {
            "category": {
                "is": {
                    "id": {
                        "equals": categoryId
                    }
                }
            },
            "AND": {
                "scientist": {
                    "is": {
                        "id": {
                            "equals": scientistId
                        }
                    }
                },
                "AND": {
                    "showing": {
                        "is": {
                            "id": {
                                "equals": showingId
                            }
                        }
                    },
                    "AND": {
                        "movie": {
                            "is": {
                                "id": {
                                    "equals": movieId
                                }
                            }
                        }
                    }
                },
            },
        }
    }
)

export default Score