import React from 'react';
import { Movie, Showing } from '../gql/graphql-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import * as colors from '@ant-design/colors';
import MovieLink from './MovieLink';
import { selectValue } from '../utils/ScoreUtils';
import { mean } from 'ramda';
import BallotLink from './BallotLink';
import SeriesLink from './SeriesLink';
import { boolean } from 'yargs';


const Table = styled.table`
    margin: 2em 1em;
    width: calc(100% - 2em);

    th {
        border-bottom: 1px ${colors.blue.primary} solid;
        text-align: left;
        padding: 0.25em;
    }

    td {
        padding: 0.25em;
    }

    tbody tr:hover {
        background-color: #e5e1e1;
    }
`


function ShowingTable({showings, excludeSeries = false}: {showings: Showing[], excludeSeries?: boolean}) {
    return (
        <Table>
            <thead>
            <tr>
                <th>
                #
                </th>
                {
                    !excludeSeries && (
                        <th>
                            Series
                        </th>
                    )
                }
                <th>
                Movie
                </th>
                <th>
                Date
                </th>
                <th>
                Ballot
                </th>
                <th>
                Avg Score
                </th>
            </tr>
            </thead>
            <tbody>
            {
                showings ? (
                showings.map((s, i) => (
                    <ShowingRow key={`showing-${s.id}`} showing={s} number={i + 1} excludeSeries={excludeSeries}></ShowingRow>
                ))
                ): (
                <tr><td>No Showings</td></tr>
                )
            }
            </tbody>
        </Table>
    )
}

function ShowingRow ({showing, number, excludeSeries}: {showing: Showing, number: number, excludeSeries: boolean}) {
    const date = showing.date;
    const series = showing.series;
    const movie = showing.movie
    const avgScore = mean(showing.scores.map(selectValue));
    return (
        <tr>
            <td>
                {number}
            </td>
            {
                !excludeSeries && (
                    <td><SeriesLink series={series} /></td>
                )
            }
            <td><MovieLink movie={movie}/></td>
            <td>{dayjs(date).format('MM/DD/YY')}</td>
            <td><BallotLink showing={showing} isSmall={true} /></td>
            <td>{!isNaN(avgScore) ? avgScore.toFixed(2) : '---'}</td>
        </tr>
    )
}

export default ShowingTable