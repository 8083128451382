import styled from '@emotion/styled';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useState } from 'react';
import ShowingSelector from '../components/selectors/ShowingSelector';
import ShowingBallot from '../components/ShowingBallot/ShowingBallot';
import { Showing, ShowingWhereUniqueInput } from '../gql/graphql-types';
import { Link, useParams } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/client';
import { GET_SHOWING, GET_SHOWINGS } from '../gql/queries/ShowingQueries';
import Page from '../components/Page';
import dayjs from 'dayjs';
import MovieTable from '../components/MovieTable';
import ShowingTable from '../components/ShowingTable';
import { Button } from 'antd';
import AddShowing from '../components/AddShowing/AddShowing';
import { EmphasisContainer } from './snips/NextShowingSnip';
// import ShowingSelector from './components/selectors/ShowingSelector';
// import ShowingBallot from './components/ShowingBallot/ShowingBallot';
// import { Showing } from './gql/graphql-types';

const HomeWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  padding: 5vw;
`

const IntroWrapper = styled.div`
  display: grid;
  width: 90vw;
`

const LinksWrapper = styled.div`
margin-top: 10px;
  display: grid;
  grid: 1fr / min-content;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  gap: 10px;
`

function BallotIndex() {
  const { id } = useParams()
  const client = useApolloClient();
  const [isEditMode, setIsEditMode] = useState(false);
  const { data, loading, error } = useQuery(GET_SHOWINGS)
  const showings = data?.showings || [];

  if (error) {
    return (
      <Page title={<>'Error'</>}>
        {JSON.stringify(error, null, 2)}
      </Page>
    )
  }

  return (
    <Page title={ loading ? <h1>'Loading Ballot...'</h1> : <h1>Ballots</h1>}>
      {
        isEditMode ? (
          <AddShowing onAdd={() => {
            client.refetchQueries({ include: [GET_SHOWINGS] });
            setIsEditMode(false)
          }}></AddShowing>
        ) : (
          <EmphasisContainer>
            <Button onClick={() => setIsEditMode(true)}>Add Showing</Button>
          </EmphasisContainer>
        )
      }
      <ShowingTable showings={showings}></ShowingTable>
    </Page>
  )
}

export default BallotIndex;
