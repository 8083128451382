import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import Showing from '../components/Showing/Showing';
import { Category, QueryFindFirstShowingArgs, Showing as ShowingType, ShowingCreateInput, ShowingWhereUniqueInput, Score, Series } from '../gql/graphql-types';
import { GET_SCORES_FOR_SHOWING, GET_SHOWING, GET_SHOWINGS } from '../gql/queries/ShowingQueries';
import { blue } from '@ant-design/colors'
import Input from 'antd/es/input/Input';
import CategorySelector from '../components/selectors/CategorySelector';
import { Maybe } from 'graphql/jsutils/Maybe';
import AddShowing from '../components/AddShowing/AddShowing';
import { Link, useNavigation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { mean, pick, prop, propOr, sortBy, sum } from 'ramda';
import { GET_ALL_SERIES, GET_SERIES_BY_ID } from '../gql/queries/SeriesQueries';
import { groupByCategory, selectCategoryId, selectValue } from '../utils/ScoreUtils';
import { ADD_CATEGORY_TO_SERIES } from '../gql/mutations/SeriesMutations';
import { Button } from 'antd';
import Page from '../components/Page';
import MovieTable, { selectMovieAvgScore } from '../components/MovieTable';
import MovieSelector from '../components/selectors/MovieSelector';
import { ADD_MOVIE_TO_SERIES } from '../gql/mutations/MovieMutations';
import ShowingTable from '../components/ShowingTable';

const SeriesDetailTable = styled.table`
  width: 100%;
`

const EmptySection = styled.section`
  display: flex;
  padding: 2vh 2vw;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.2);
`

const CategoryWrapper = styled.section`

`

function SeriesDetail() {
  const { id } = useParams();
  const [newCategoryName, setNewCategoryName] = useState<Maybe<String>>(null)
  const { data, loading, error } = useQuery(GET_SERIES_BY_ID, {
    variables: {
      "where": {
        "id": {
          "in": Number(id)
        }
      }
    }
  });

  const [addCategoryToSeries, mutation] = useMutation(ADD_CATEGORY_TO_SERIES);

  const series: Series = data?.series;
  const categories: Category[] = series?.categories || [];

  console.log("movies", series?.movies)
  if (error) {
    throw new Error(error.message)
  }

  return (
    <Page title={<h1>{series?.name}</h1>}>
      {loading ? 'Loading...' : (
        <>
          <h2>Top Movies</h2>
          <MovieTable movies={series.movies.filter(selectMovieAvgScore)} selectSort={selectMovieAvgScore} />
          <hr/>
          <h2>Categories</h2>
          {
            <EmptySection>
              <div>
                <CategoryWrapper>
                  {
                    categories.map(c => {
                      return (
                        <div>
                          <strong>{c.name}</strong>
                          <div>
                            {c.description}
                          </div>
                        </div>
                      )
                    })
                  }
                </CategoryWrapper>
                <CategorySelector onChange={c => setNewCategoryName(c)} onSelect={(c => setNewCategoryName(c?.name))} /> <Button disabled={!newCategoryName} onClick={() => addCategoryToSeries({ variables: { categoryName: newCategoryName, seriesId: series.id }})}>Add</Button>
              </div>
            </EmptySection>
          }
          <hr/>
          <h2>Add Movie to Series</h2>
          <AddMovieToSeries seriesId={series.id} onSet={() => {}}  />
          <hr/>
          <h2>Unscienced Movies</h2>
          <MovieTable movies={series.movies.filter(m => selectMovieAvgScore(m) === 0)}></MovieTable>
        </>
      )}
    </Page>
  );
}


function AddMovieToSeries ({ seriesId, onSet = () => {}}: { seriesId: number, onSet: () => any }) {
  const client = useApolloClient()
  const [addMovieToSeries, { loading: setLoading, error: setError }] = useMutation(ADD_MOVIE_TO_SERIES)

  return (
      <div>
          <MovieSelector onSelect={async (movie) => {
              if (movie) {
                  await addMovieToSeries({
                      variables: {
                          movieId: movie.id,
                          seriesId
                      }
                  })
                 
                  const updates = await client.refetchQueries({
                      include: [GET_SERIES_BY_ID]
                  });

                  console.log(updates)
                  onSet()
              }
          }} />
      </div>
  )
}



export default SeriesDetail;
