import { gql } from "@apollo/client"

export const SCORE_FRAGMENT = gql`
fragment ScoreFragment on Score {
  id
  value
  category {
    id
    name
    type
  }
  scientist {
    id
    name
  }
  movie {
    id
    title
  }
  showing {
    id
    date
  }
}
`

export const GET_SCORE = gql`
query GetScore($where: ScoreWhereInput) {
 score: findFirstScore(where: $where) {
   ...ScoreFragment
 }
}
${SCORE_FRAGMENT}
`
