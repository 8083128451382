import { useApolloClient, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import Showing from '../components/Showing/Showing';
import { Category, Showing as ShowingType } from '../gql/graphql-types';
import { GET_SHOWINGS } from '../gql/queries/ShowingQueries';
import { blue } from '@ant-design/colors'
import Input from 'antd/es/input/Input';
import CategorySelector from '../components/selectors/CategorySelector';
import { Maybe } from 'graphql/jsutils/Maybe';
import AddShowing from '../components/AddShowing/AddShowing';
import { useNavigate } from 'react-router-dom';
import Page from '../components/Page';
import ShowingTable from '../components/ShowingTable';
import { EmphasisContainer } from './snips/NextShowingSnip';
import { Button } from 'antd';

function Showings() {
  const { data, loading, error } = useQuery(GET_SHOWINGS);
  const client = useApolloClient();
  const [isEditMode, setIsEditMode] = useState(false);
  // const [showing, setShowing] = useState<Maybe<Showing>>(null);
  const showings = data?.showings || [];

  return (
    <Page title={<h1>Showings</h1>}>
      {loading ? 'Loading...' : (
        <>
          <section>
            {
              isEditMode ? (
                <AddShowing onAdd={() => {
                  client.refetchQueries({ include: [GET_SHOWINGS] });
                  setIsEditMode(false)
                }}></AddShowing>
              ) : (
                <EmphasisContainer>
                  <Button onClick={() => setIsEditMode(true)}>Add Showing</Button>
                </EmphasisContainer>
              )
            }
            <ShowingTable showings={showings} />
          </section>
        </>
      )}
    </Page>
  );
}

const AddAShowingWrapper = styled.div`
  display: grid;
  grid: 1fr / 1fr 1fr 1fr;
`

const AddAShowingPrompt = styled.div`
  grid-area: 1 / 2;
  padding: 1em;
  text-align: center;
  color: ${blue[7]};
  transition: background ease-in-out 200ms, color ease-in-out 180ms;
  cursor: pointer;

  &:hover {
    background: ${blue[7]};
    color: white;

  }
`


const AddAShowingForm = styled.div`
  grid-area: 1 / span 3;
  padding: 1em;
  text-align: center;
  color: ${blue[7]};
  transition: background ease-in-out 200ms, color ease-in-out 180ms;
  cursor: pointer;

  &:hover {
    background: ${blue[7]};
    color: white;

  }
`


function AddAShowing() {
  const [isEditMode, setIsEditMode] = useState(false)
  const nav = useNavigate()

  return (
    <AddAShowingWrapper>
      {
        isEditMode ? (
          <AddAShowingForm>
            <AddShowing onAdd={(s) => {
              nav(`/showing/${s.id}`)
            }} />
          </AddAShowingForm>
        ) : (
          <AddAShowingPrompt onClick={() => setIsEditMode(true)}>
            <strong>Add a showing?</strong>
          </AddAShowingPrompt>
        )
      }

    </AddAShowingWrapper>
  )
}

export default Showings;
