import React, { useState } from 'react';
import {  GET_SCIENTISTS_LIKE } from '../../gql/queries/ScientistQueries';
import { Scientist } from '../../gql/graphql-types';
import { Maybe } from 'graphql/jsutils/Maybe';
import Selector from './Selector';

interface ScientistSelectorProps {
    onSelect: (scientist: Maybe<Scientist>) => unknown
}

function ScientistSelector({ onSelect }: ScientistSelectorProps) {
    const [isEdit, setIsEdit] = useState(false);

    return (
        <Selector
            name="Scientist"
            query={GET_SCIENTISTS_LIKE}
            accessor={(res) => {
                return res?.scientists || []
            }}
            labelSelector={(s: Scientist) => s.name}
            onSelect={onSelect}
            where={value => {
                return { name: { contains: value } }
            }}
        />
    )
}

export default ScientistSelector