import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './routes/Home';
import Showings from './routes/Showings';
import ShowingPage from './routes/ShowingPage';
import ErrorBoundary from './components/ErrorBoundary';
import Movies from './routes/Movies';
import SeriesIndex from './routes/SeriesIndex';
import SeriesDetail from './routes/SeriesDetail';
import MoviePage from './routes/MoviePage';
import BallotPage from './routes/BallotPage';
import SeriesNew from './routes/SeriesNew';
import NewShowing from './routes/NewShowing';
import BallotIndex from './routes/BallotIndex';

import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc"
dayjs.extend(utc)

const client = new ApolloClient({
  uri: process.env.REACT_APP_BREEN_API_ADDR,
  cache: new InMemoryCache(),
});

//@ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    "path": "/",
    "element": <Home />
  },
  {
    "path": "/showings",
    "element": <Showings />
  }, {
    "path": "/showings/:id",
    "element": <ShowingPage />
  },
  {
    "path": "/movies",
    "element": <Movies />
  },
  {
    "path": "/movies/:id",
    "element": <MoviePage />
  },
  {
    "path": "/series",
    "element": <SeriesIndex />
  },
  {
    "path": "/series/:id",
    "element": <SeriesDetail />
  },
  {
    "path": "/ballots/",
    "element": <BallotIndex />
  },
  {
    "path": "/ballots/:id",
    "element": <BallotPage />
  },{
    "path": "/new/series",
    "element": <SeriesNew />
  },{
    "path": "/new/showing",
    "element": <NewShowing />
  },
])
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <RouterProvider router={router} fallbackElement={<ErrorBoundary />} />
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
