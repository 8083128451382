import React from 'react';
import Page from '../components/Page';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Series } from '../gql/graphql-types';
import styled from "@emotion/styled";
import CurrentSeriesSnip from './snips/SeriesSnip';
import BestMovieSnip from './snips/BestMovieSnip';
import { GET_CURRENT_SERIES } from '../gql/queries/SeriesQueries';
import MovieTable from '../components/MovieTable';
import NextShowingSnip from './snips/NextShowingSnip';

export interface HomeProps {
  hasShowingToday?: boolean
}

function Home({}: HomeProps) {
  const now = dayjs(new Date());
  // const { data, loading, error } = useQuery(GET_NEXT_SHOWING, { variables: { date: now.subtract(1, 'day').format("MM/DD/YYYY") } })
  // const nextShowing: Showing = data?.nextShowing || {};
  // const nextShowingDate = dayjs(nextShowing.date);

  const { data, loading, error } = useQuery(GET_CURRENT_SERIES)
  const currentSeries: Series = data?.series
  const movies = currentSeries?.movies || []

  // const hasNextShowing = !!nextShowing
  // const showingIsToday = nextShowingDate.isSame(dayjs(), 'day');

  return (
    <Page 
      title={<Page.Title>Welcome, friend!</Page.Title>}
    >
      {
        !loading && (
          <>
            <NextShowingSnip />
            <div/>
            <BestMovieSnip />
            <div/>
            <MovieTable movies={movies} />
          </>
        )
      }
    </Page>
  );
}

export default Home;
