import { gql } from "@apollo/client"

export const GET_SCIENTISTS = gql`
  query GetScientists {
  scientists {
    id
    name
    createdAt
    SubmittedMovies {
      id
      title
    }
  }
}
`

export const GET_SCIENTISTS_LIKE = gql`
query GetScientistsLike($where: ScientistWhereInput) {
  scientists(where: $where) {
    id
    name
    createdAt
    submittedMovies {
      id
      title
    }
  }
}
`
