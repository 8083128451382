import React, { useState } from 'react';
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Movie, Series, Showing, ShowingCreateInput } from '../../gql/graphql-types';
import { GET_CURRENT_SERIES } from '../../gql/queries/SeriesQueries';
import { Button, DatePicker } from 'antd';
import SeriesSelector from '../../components/selectors/SeriesSelector';
import { SET_CURRENT_SERIES, UNSET_CURRENT_SERIES } from '../../gql/mutations/SeriesMutations';
import SeriesLink from '../../components/SeriesLink';
import { GET_NEXT_SHOWING } from '../../gql/queries/ShowingQueries';
import dayjs, { Dayjs } from 'dayjs';
import MovieLink from '../../components/MovieLink';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { ADD_SHOWING } from '../../gql/mutations/ShowingMutations';
import { Maybe } from 'graphql/jsutils/Maybe';
import MovieSelector from '../../components/selectors/MovieSelector';

export const EmphasisContainer = styled.section`
    padding: 1em;
    border: 1px black solid;
    margin-bottom: 1.5em;
`

function NextShowingSnip() {
    const client = useApolloClient();
    const now = dayjs();
    const { data, loading, error } = useQuery(GET_NEXT_SHOWING, { variables: { date: now.format("MM/DD/YYYY") } })
    const nextShowing: Showing = data?.nextShowing;
    const nextShowingDate = dayjs(nextShowing?.date);
    const isShowingToday = nextShowingDate.hour(0).isSame(now.hour(0), 'day')
    const [isOpen, setIsOpen] = useState(false);

    if (loading) {
        return <span>Loading...</span>
    }

    if (!nextShowing) {
        return (
            <EmphasisContainer>
                Looks like we don't have a next showing set yet. <Button onClick={() => setIsOpen(true)}>Wanna set one?</Button>
                {
                    isOpen && (
                        <AddNewShowing onSet={() => {
                            setIsOpen(false)
                            client.refetchQueries({ include: [GET_NEXT_SHOWING] })
                        }}/>
                    )
                }
            </EmphasisContainer>
        )
    }

    if (isShowingToday) {
        return (
            <EmphasisContainer>
                <span>🎥 It's Movie Night! We're watching <MovieLink movie={nextShowing?.movie} />! <Link to={`/ballots/${nextShowing?.id}`}>Ready to vote?</Link> </span>
            </EmphasisContainer>
        )
    }

    return (
        <>
            <span>
                The next showing is <MovieLink movie={nextShowing.movie} /> on <Link to={`/showings/${nextShowing.id}`}>{dayjs(nextShowing.date).format('MMM D')}</Link>.
            </span>
        </>
    )
}

const AddNewShowingWrapper = styled.div`
    display: grid;
    grid: 1fr / 2fr 2fr 2fr 1fr;
    gap: 1em;
    align-items: center;
    margin-top: 1em;
`

function AddNewShowing ({onSet = () => {}}) {
    const client = useApolloClient()

    const [date, setDate] = useState<Dayjs | null>(null);
    const [series, setSeries] = useState<Maybe<Series>>(null);
    const [movie, setMovie] = useState<Maybe<Movie>>(null);

    const [addNewShowing, {loading, error}] = useMutation(ADD_SHOWING)

    const canSubmit = movie && date && series

    return (
        <AddNewShowingWrapper>
            <MovieSelector onSelect={(m) => setMovie(m)}/>
            <SeriesSelector onSelect={(s) => setSeries(s)}/>
            <DatePicker onChange={(date) => setDate(date)} />
            <Button disabled={!canSubmit} onClick={async () => {
                if (canSubmit) {
                    await addNewShowing({
                        variables: {
                            data: {
                                date,
                                movie: { connect: { id: movie?.id } },
                                series: { connect: { id: series?.id } },
                            }as ShowingCreateInput
                        }
                    })
                    await client.refetchQueries({
                        include: [GET_CURRENT_SERIES]
                    })
                    onSet()
                }
            }}>Add</Button>
        </AddNewShowingWrapper>
    )
}



export default NextShowingSnip