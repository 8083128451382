import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { GET_ALL_SERIES } from '../gql/queries/SeriesQueries';
import Page from '../components/Page';
import { Series } from '../gql/graphql-types';
import { Button, Input } from 'antd';
import styled from '@emotion/styled';
import { ADD_SERIES } from '../gql/mutations/SeriesMutations';
import { add } from 'ramda';
import SeriesTable from '../components/SeriesTable';
import { EmphasisContainer } from './snips/NextShowingSnip';

const Title = styled.div`
  display: grid;
  grid: 1fr / min-content min-content;
  gap: 1em;
  align-items: center;
`

function SeriesIndex() {
  const { data, loading, error } = useQuery(GET_ALL_SERIES);
  const series: Series[] = data?.series;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Page title={loading ? <>'Loading...'</> : <Title><h1>Series</h1> </Title>}>
      {!loading && (
        <>
          <EmphasisContainer>
            {
              isOpen ? <AddSeries onSet={() => setIsOpen(false)} /> : <Button onClick={() => setIsOpen(true)}>Add Series</Button>
            }
          </EmphasisContainer>
          <SeriesTable allSeries={series} />
        </>
      )}
    </Page>
  );
}

const AddSeriesWrapper = styled.div`
  display: grid;
  grid: 1fr / 1fr min-content;
  gap: 1em;
`

function AddSeries({ onSet }: { onSet: () => any }) {
  const client = useApolloClient()
  const [addSeries, { loading, error }] = useMutation(ADD_SERIES)
  const [seriesName, setSeriesName] = useState('');

  return (
    <AddSeriesWrapper>
      <Input placeholder='New Series Name' onChange={(e) => setSeriesName(e.currentTarget.value)} value={seriesName} />
      <Button disabled={!seriesName || loading} onClick={async () => {
        await addSeries({
          variables: {
            name: seriesName
          }
        })
        await client.refetchQueries({ include: [GET_ALL_SERIES] })
        onSet()
      }}>Add New Series</Button>
    </AddSeriesWrapper>
  )
}


export default SeriesIndex;
