import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { Category } from '../../gql/graphql-types';
import { GET_CATEGORIES, GET_CATEGORIES_LIKE } from '../../gql/queries/CategoryQueries';
import Selector from './Selector';

interface CategorySelectorProps {
  onSelect: (category: Maybe<Category>) => unknown
  onChange?: (string: string) => unknown
}

function CategorySelector({ onSelect, onChange = () => {} }: CategorySelectorProps) {
  return (
    <Selector
      name="Category"
      query={GET_CATEGORIES_LIKE}
      accessor={(res) => {
        return res?.categories || []
      }}
      labelSelector={(c: Category) => c.name}
      onChange={onChange}
      onSelect={onSelect}
      where={value => {
        console.log({ name: { contains: value } })
        return { name: { contains: value } }
      }}
    />
  )

}

export default CategorySelector