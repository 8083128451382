import React from 'react';
import { Movie, Series } from '../gql/graphql-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import * as colors from '@ant-design/colors';
import MovieLink from './MovieLink';
import { selectValue } from '../utils/ScoreUtils';
import { mean, sortBy } from 'ramda';
import { pathOr } from 'ramda';
import { reverse } from 'ramda';
import SeriesLink from './SeriesLink';
import { Checkbox } from 'antd';
import { useApolloClient, useMutation } from '@apollo/client';
import { UNSET_CURRENT_SERIES, SET_CURRENT_SERIES } from '../gql/mutations/SeriesMutations';
import { GET_ALL_SERIES } from '../gql/queries/SeriesQueries';


const Table = styled.table`
    margin: 2em 1em;
    width: calc(100% - 2em);

    th {
        border-bottom: 1px ${colors.blue.primary} solid;
        text-align: left;
        padding: 0.25em;
    }

    td {
        padding: 0.25em;
    }

    tbody tr:hover {
        background-color: #e5e1e1;
    }
`


function SeriesTable({allSeries}: {allSeries: Series[]}) {
    const selectSeriesId: (s: Series) => string = pathOr("", ["id"]) ;
    const sortedAllSeries = reverse(sortBy(selectSeriesId, allSeries))
    return (
        <Table>
            <thead>
            <tr>
                <th />
                <th style={{width: "100%"}}>
                    Title
                </th>
                <th>
                    Active?
                </th>
            </tr>
            </thead>
            <tbody>
            {
                allSeries ? (
                sortedAllSeries.map((s, i) => (
                    <SeriesRow key={`series-${s.id}`} series={s} number={i + 1} />
                ))
                ): (
                <tr><td>No Series</td></tr>
                )
            }
            </tbody>
        </Table>
    )
}

function SeriesRow ({series, number}: {series: Series, number: number}) {
    const client = useApolloClient();
    const [unsetCurrentSeries, {loading: unsetLoading}] = useMutation(UNSET_CURRENT_SERIES);
    const [setCurrentSeries, {loading: setLoading}] = useMutation(SET_CURRENT_SERIES);


    return (
        <tr>
            <td style={{textAlign: 'center'}}>{number}</td>
            <td>
                <SeriesLink series={series} />
            </td>
            <td style={{textAlign: "center"}}>
                <Checkbox checked={series.isCurrent} disabled={unsetLoading || setLoading} onClick={async () => {
                    await unsetCurrentSeries()
                    await setCurrentSeries({ variables: { seriesId: series.id }})
                    client.refetchQueries({ include: [GET_ALL_SERIES] })
                } } />
            </td>
        </tr>
    )
}

export default SeriesTable