import { useQuery } from '@apollo/client'
import React from 'react'
import styled from '@emotion/styled'
import { green } from '@ant-design/colors'
import ErrorBoundary from '../ErrorBoundary'
import { GET_SCORES_FOR_SHOWING, GET_SHOWING } from '../../gql/queries/ShowingQueries'
import { Score as ScoreType } from '../../gql/graphql-types'
import {mean} from 'ramda';
import { useNavigate } from 'react-router-dom'

const getToday = () => {
    const now = new Date();

    return `${now.getDay()}/${now.getMonth()}/${now.getFullYear()}`
}

const ShowingWrapper = styled.div`
    display: grid;
    grid: 1fr min-content min-content / 1fr 1fr auto;
    padding: 1vh; 
    border: 1px solid black;
    border-radius: 5px;
    /* padding: 15px;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,.5);
    min-width: 70vw; */

`
const MovieSeries = styled.strong`
    grid-area: 1 / 3;
    font-size: 0.75rem;
    font-variant: small-caps;
    margin: 0;
`

const MovieTitle = styled.h2`
    grid-area: 2 / span 3;
    cursor: pointer;
    margin: 0;
`

const MovieDate = styled.h3`
    grid-area: 3 / 3 span;
    font-size: 2vh;
    /* color: ${green[2]}; */
    margin: 0;
`

const LoadingMessage = styled.span`
    /* grid-area: 2 / 2; */
`

interface ShowingProps {
    id?: number
}

function Showing({ id }: ShowingProps) {
    const { data, loading, error } = useQuery(GET_SHOWING, { variables: { where: { id } } });
    const nav = useNavigate();
    const showing = data?.showing

    const date = new Date(showing?.date)
    const series = showing?.series
    if (!showing && !loading) {
        return (
            <ShowingWrapper>
                Unable to retrieve data for series {id}
            </ShowingWrapper>
        )
    }
    return (
        <ErrorBoundary>
            <ShowingWrapper>
                {
                    loading ? <LoadingMessage>Loading...</LoadingMessage> : (
                        <>
                            <MovieTitle onClick={() => {
                                nav(`/showings/${showing.id}`)
                            }} >{showing?.movie?.title || "No Movie Selected"} </MovieTitle>
                            { showing && <ShowingScore showingId={showing.id} />}
                            <MovieDate>{date.toLocaleDateString() || "No Date"}</MovieDate>
                            <MovieSeries>{series?.name}</MovieSeries>
                            
                        </>
                    )
                }
            </ShowingWrapper>
        </ErrorBoundary>
    )
}

function ShowingScore ({showingId}: {showingId: number}) {
    const { loading, data } = useQuery(GET_SCORES_FOR_SHOWING, { variables: { where: { id: showingId } }})

    const scores: ScoreType[] = data?.showing?.scores || []

    const values =   scores.map((s: ScoreType) => s.value)


    if (scores.length === 0) {
        return null
    }

    if (loading) {
        return <span>'loading...'</span>
    }

    return (
        <span>Score { mean(values).toFixed(2) }</span>
    )
}


export default Showing