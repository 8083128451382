import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import Showing from '../components/Showing/Showing';
import { Category, QueryFindFirstShowingArgs, Showing as ShowingType, ShowingCreateInput, ShowingWhereUniqueInput, Score } from '../gql/graphql-types';
import { GET_SCORES_FOR_SHOWING, GET_SHOWING, GET_SHOWINGS, GET_SHOWING_WITH_SCORES } from '../gql/queries/ShowingQueries';
import { blue } from '@ant-design/colors'
import Input from 'antd/es/input/Input';
import CategorySelector from '../components/selectors/CategorySelector';
import { Maybe } from 'graphql/jsutils/Maybe';
import AddShowing from '../components/AddShowing/AddShowing';
import { useNavigation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { mean } from 'ramda';
import Page from '../components/Page';
import BallotLink from '../components/BallotLink';
import { EmphasisContainer } from './snips/NextShowingSnip';
import ScoreTable from '../components/ScoreTable';

const ScoreGrid = styled.section`
  display: grid;
  grid: 1fr / 1fr 2fr 1fr;
  padding: 0 2vw;
`

const ScoreValue = styled.span`
  text-align: right;
`

const ScoreTotal = styled.span`
  font-weight: 500;
  grid-area: auto / 3;
  text-align: right;
  font-size: 2em;
`


function ShowingPage() {
  const { id } = useParams();
  const { data, loading, error } = useQuery(GET_SHOWING_WITH_SCORES, { variables: { where: { id: Number(id) } } as ShowingWhereUniqueInput });
  const showing = data?.showing;
  const date = dayjs(showing?.date);
  const { data: scoresData } = useQuery(GET_SCORES_FOR_SHOWING, { variables: { where: { id: Number(id) } } as ShowingWhereUniqueInput })

  const scores = scoresData?.showing?.scores || [];

  const total = mean(scores.map((s: Score) => s.value))

  return (
    <Page title={<h1>{ <><em>{showing?.movie?.title}</em>  on {dayjs(showing?.date).format("MMM HH YYYY")}</>|| "Loading..."}</h1>}>
      <EmphasisContainer>
        <h3>Ballot</h3>
        <BallotLink showing={showing}/>
      </EmphasisContainer>
      {loading ? 'Loading...' : (
        <>
          <h2>{date?.format("MMM DD")}</h2>
          <h3>{showing?.series?.name}</h3>

          {
            scores.length > 0 ? (
              <>
                <h3>Scores</h3>
                <ScoreTable scores={scores}/>
              </>
            ) : (
              <h4>No Scores Found</h4>
            )
          }


        </>
      )}
    </Page>
  );
}


export default ShowingPage;
