import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { green } from '@ant-design/colors'
import ErrorBoundary from '../ErrorBoundary'
import { ADD_SHOWING } from '../../gql/mutations/ShowingMutations'
import { Movie, Series, Showing, ShowingCreateInput } from '../../gql/graphql-types'
import { Button, DatePicker } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime';
import MovieSelector from '../selectors/MovieSelector'
import { Maybe } from 'graphql/jsutils/Maybe'
import SeriesSelector from '../selectors/SeriesSelector'
import assert from 'assert'
import { useNavigate } from 'react-router-dom'

const ShowingWrapper = styled.div`
    /* display: grid; */
    /* grid: 1fr min-content min-content / 1fr 1fr auto; */
    padding: 1vh; 
    max-width: 70vw;
    border: 1px solid black;
    border-radius: 5px;
    min-height: 100px;
    /* padding: 15px;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,.5);
    min-width: 70vw; */

`
const MovieSeries = styled.strong`
    grid-area: 1 / 3;
    font-size: 0.75rem;
    font-variant: small-caps;
    margin: 0;
`

const MovieTitle = styled.h2`
    grid-area: 2 / span 3;
    /* color: ${green[4]}; */
    margin: 0;
`

const MovieDate = styled.h3`
    grid-area: 3 / 3 span;
    font-size: 2vh;
    /* color: ${green[2]}; */
    margin: 0;
`

const LoadingMessage = styled.span`
    /* grid-area: 2 / 2; */
`

const FormWrapper = styled.div`
    display: grid;
    grid: 1fr / 2fr 2fr 2fr 1fr;
    align-items: center;
    gap: 16px;
`


function AddShowing({ onAdd }: { onAdd: (showing: Showing) => unknown }) {
    dayjs.extend(relativeTime)

    const [date, setDate] = useState<Dayjs | null>(null);
    const [series, setSeries] = useState<Maybe<Series>>(null);
    const [movie, setMovie] = useState<Maybe<Movie>>(null);

    const nav = useNavigate();
    const [addShowing, { data, loading, error }] = useMutation(ADD_SHOWING);

    // const displayDate = () => (<div>{date?.day}/{date?.month}/{date?.year}</div>)

    return (
        // <ErrorBoundary>
        <ShowingWrapper>
            {
                loading ? <LoadingMessage>Loading...</LoadingMessage> : (
                    <FormWrapper>
                        <DatePicker onChange={(date) => setDate(date)} />
                        <MovieSelector onSelect={(m) => {
                            if (m) {
                                setMovie(m)
                            }
                        }} />
                        <SeriesSelector onSelect={(s) => {
                            if (s) {
                                console.log(s)
                                setSeries(s)
                            }
                        }} />
                        <Button disabled={!series || !movie || !date} onClick={async () => {
                            if (!series || !movie || !date) {
                                window.alert("You're missing something! Try again!")
                            }

                            const newShowing = await addShowing({
                                variables: {
                                    data: {
                                        date,
                                        movie: { connect: { id: movie?.id } },
                                        series: { connect: { id: series?.id } },
                                    } as ShowingCreateInput
                                }
                            })

                            const id = newShowing.data?.createOneShowing.id
                            onAdd(newShowing.data?.createOneShowing as Showing)
                        }} >
                            Submit
                        </Button>
                    </FormWrapper>
                )
            }
        </ShowingWrapper>
        // </ErrorBoundary>
    )
}


function dateToRelative(date: Dayjs) {
    if (date.isSame(dayjs(), 'day')) {
        return "Today"
    } else {
        return date.fromNow()
    }
}

function dateToString(date: Dayjs) {
    return date.format("MMMM Do")
}

export default AddShowing