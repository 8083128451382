import React from 'react';
import { Movie, Score } from '../gql/graphql-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import * as colors from '@ant-design/colors';
import MovieLink from './MovieLink';
import { selectValue } from '../utils/ScoreUtils';
import { mean, sortBy } from 'ramda';
import { valueToColor } from './Score';


const Table = styled.table`
    margin: 2em 1em;
    width: calc(100% - 2em);

    th {
        border-bottom: 1px ${colors.blue.primary} solid;
        text-align: left;
        padding: 0.25em;
    }

    td {
        padding: 0.25em;
    }

    tbody tr:hover {
        background-color: #e5e1e1;
    }
`


function ScoreTable({scores}: {scores: Score[]}) {
    const sortedScores = sortBy((s) => s.scientist.name, scores);

    return (
        <Table>
            <thead>
            <tr>
                <th>
                Scientist
                </th>
                <th>
                Category
                </th>
                <th>
                Value
                </th>
            </tr>
            </thead>
            <tbody>
            {
                scores ? (
                    <>
                        {
                            sortedScores.map(s => (
                                <ScoreRow key={`score-${s.id}`} score={s}></ScoreRow>
                            ))
                        }
                        <tr>
                            <td colSpan={2}><strong>Total</strong></td>
                            <td> <strong> <em>{mean(scores.map(selectValue)).toFixed(2)}</em> </strong></td>
                        </tr>
                    </>
                ): (
                <tr><td>No Scores</td></tr>
                )
            }
            </tbody>
        </Table>
    )
}

function ScoreRow ({score}: {score: Score}) {
    const scientist = score.scientist;
    const value = score.value || [];
    const category = score.category || [];
    const backgroundColor = valueToColor(value)
    return (
        <tr>
            <td>
                {scientist.name}
            </td>
            <td>{category.name || "---"}</td>
            <td style={{backgroundColor, textAlign: "center", fontSize: "1.2em", color: "white"}}><strong>{value || '---'}</strong></td>
        </tr>
    )
}

export default ScoreTable